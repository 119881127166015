import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef} from 'react';
import md5 from 'js-md5';
import { useUserContext } from "../../contexts";
import { searchAssets, subscribeUserFn, meFn, Analytics, deleteFaultyCookies } from '../../lib/api';
import useClickable from '../../hooks/useClickable';
import Menu from './Menu';
import Logo from './logo.svg';
import LogoName from './logo.name.svg';
import LogoIcon from './logo.icon.svg';
import { Spinner } from '../Spinner';
import Survey from '../Survey/Survey';
import Banner from './Banner';
import ProfilePicture from './ProfilePicture';
import ParticleSystem from '../LabCTA/ParticleSystem';
import ButtonWithGradient from '../LabCTA/ButtonWithGradient';

import './Header.scss';
import s from '../Survey/Survey.module.scss';

import CATEGORIES from './categories.json';

const BASE_URL_MAP = {
    'development': 'http://crate-core.com',
    'qa': 'https://user-qa.productioncrate.com',
    'production': 'https://user.productioncrate.com',
    'webapp': 'https://user.productioncrate.com'
}
const getEnvironment = () => {
    if (window.location.hostname==='app.productioncrate.com') {
        //console.log("ENV", "webapp");
        return 'webapp';
    }
    const env = window.location.hostname === "localhost" ? "development" : window.location.hostname === "user-qa.productioncrate.com" ? "qa" : "production";
    return env;
};
const env = getEnvironment();



const Header = ({minimal=false, external=false, newUrlScheme=false}, ref) => {

    
    const {user, unloadUser, focusMode, isAuthenticating, assetsAvailable} = useUserContext();

    const [searchQuery, setSearchQuery] = useState('');

    const [premiumAssetsPolicy, setPremiumAssetsPolicy] = useState(false);
    

    const [loading, setLoading] = useState(false);

    const [floatingVisible, setFloatingVisible] = useState(false);
    const [searchFocused, setSearchFocused] = useState(false);
    const [selectorFocus, setSelectorFocus] = useState(false);
    const [categorySelectorExpanded, setCategorySelectorExpanded] = useState(false);
    const [categorySelected, setCategorySelected] = useState('all');
    
    const baseUrl = BASE_URL_MAP[env];
    const baseHref = external?baseUrl:'';

    const [showMenuContent, setShowMenuContent] = useState(false);


    const showBrandSubtitle = ['rendercrate', 'footagecrate', 'graphicscrate', 'soundscrate']
    .some(domain => window.location.hostname.includes(domain));


    const [surveyVisible, setSurveyVisible] = useState(false);
    const [thankYou, setThankYou] = useState(false);
    const [showUserProfileBanner, setShowUserProfileBanner] = useState(false);
    const [usedForText, setUsedForText] = useState('');

    const [answered, setAnswered] = useState(0);
    const [surveyData, setSurveyData] = useState({
        thankYou: 'Thanks, your profile is 100% complete! Time to make it awesome!',
        name: "how_you_found_us",
        flagName: "crate.surveyFlagId1",
        questions: [
          {
            labelAccent: "1 of 3",
            label: "What is your profession?",
            value: 'profession',
            cta: "Next",
            answers: [
                {
                  label: "🎥 Vlogger",
                  value: 'vlogger',
                  selected: false,
                },
                {
                  label: "💼 Business owner",
                  value: 'business-owner',
                  selected: false,
                },
                {
                  label: "🎨 VFX artist",
                  value: 'vfx-artist',
                  selected: false,
                },
                {
                  label: "🖌️ Motion graphics artist",
                  value: 'motion-graphics-artist',
                  selected: false,
                },
                {
                  label: "📚 Teacher",
                  value: 'teacher',
                  selected: false,
                },
                {
                  label: "🎓 Student",
                  value: 'student',
                  selected: false,
                },
                {
                  label: "✂️ Video editor",
                  value: 'video-editor',
                  selected: false,
                },
                {
                  label: "🎬 Filmmaker",
                  value: 'filmmaker',
                  selected: false,
                },
                {
                  label: "💻 Developer",
                  value: 'developer',
                  selected: false,
                },
                {
                  label: "❓ Other",
                  value: 'other',
                  selected: false,
                },
              ]
          },
          {
            labelAccent: "2 of 3",
            label: "What software do you use?",
            value: 'software_preferences',
            cta: "Next",
            answers: [
                {
                    label: "After Effects",
                    value: "after-effects",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/after_effects.svg"
                },
                {
                    label: "iMovie",
                    value: "imovie",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/imovie.svg"
                },
                {
                    label: "Adobe Premiere",
                    value: "adobe-premiere",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/premiere_pro.svg"
                },
                {
                    label: "DaVinci Resolve",
                    value: "davinci-resolve",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/davinci.svg"
                },
                {
                    label: "Final Cut",
                    value: "final-cut",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/final_cut.svg"
                },
                {
                    label: "Wondershare Filmora",
                    value: "wondershare-filmora",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/filmora.svg"
                },
                {
                    label: "Sony Vegas",
                    value: "sony-vegas",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/sony_vegas.svg"
                },
                {
                    label: "BlackMagic Fusion",
                    value: "blackmagic-fusion",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/blackmagic_fusion.png"
                },
                {
                    label: "Kinemaster",
                    value: "kinemaster",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/kine_master.svg"
                },
                {
                    label: "Nuke",
                    value: "nuke",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/nuke.svg"
                },
                {
                    label: "Blender",
                    value: "blender",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/blender.svg"
                },
                {
                    label: "Power Director",
                    value: "power-director",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/power_director.png"
                },
                {
                    label: "Photoshop",
                    value: "photoshop",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/photoshop.svg"
                },
                {
                    label: "CapCut",
                    value: "capcut",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/capcut.png"
                },
                {
                    label: "Cinema 4D",
                    value: "cinema-4d",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/cinema4d.png"
                },
                {
                    label: "Houdini",
                    value: "houdini",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/houdini.png"
                },
                {
                    label: "Maya",
                    value: "maya",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/maya.png"
                },
                {
                    label: "Unity",
                    value: "unity",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/unity.svg"
                },
                {
                    label: "Unreal Engine",
                    value: "unreal-engine",
                    selected: false,
                    icon: "https://user.productioncrate.com/images/logos/unreal_engine.png"
                },
                {
                    label: "Other",
                    value: "other",
                    selected: false,
                    icon: null
                }
            ]
          },
          {
            labelAccent: "3 of 3",
            label: "What is your usage purpose?",
            value: 'usage_purpose',
            cta: "Send",
            answers: [
                { label: "Not Sure", value: "not-sure", selected: false },
                { label: "School Project", value: "school-project", selected: false },
                { label: "Films", value: "feature-film", selected: false },
                { label: "Commercials", value: "client-projects-commercials", selected: false },
                { label: "YouTube", value: "youtube", selected: false },
                { label: "Just exploring!", value: "just-browsing", selected: false },
                { label: "Games", value: "games", selected: false },
                { label: "Instagram", value: "instagram", selected: false },
                { label: "TikTok", value: "tiktok", selected: false },
                { label: "Music Videos", value: "music-videos", selected: false },
                { label: "Graphics", value: "graphic-design", selected: false },
                { label: "VFX Shots", value: "vfx-shots", selected: false },
                { label: "Other Socials", value: "other-socials", selected: false },
                { label: "Learning/Practice", value: "learning-practice", selected: false },
                { label: "Other", value: "other", selected: false }
            ]
          }
        ],
    });
    const submitAnswer = (j, i) => {
        //surveyData.questions[j].answers[i].selected = true;
        console.log("SURVEY", j, i, surveyData.questions[j].answers[i]);
        setSurveyData(prev => {
            let newData = {...prev};
            newData.questions[j].answers[i].selected = !newData.questions[j].answers[i].selected;

            console.log(newData.questions[j].answers[i]);
            return newData;
        });
    };


    useEffect(() => {
        Analytics.view('page', {target: window.location.href});
        deleteFaultyCookies();

        if (['sfx', 'soundscrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical sounds)");
            setCategorySelected('sounds');
        } 
        else if (window.location.pathname.startsWith("/3D") || ['3d.productioncrate.com', 'rendercrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical 3d");
            setCategorySelected('3d');
        }
        else if (['vfx', 'footagecrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical vfx");
            setCategorySelected('vfx');
        }
        else if (['graphics', 'graphicscrate'].some(sub => window.location.hostname.includes(sub))) {
            console.log("vertical graphics");
            setCategorySelected('graphics');
        }
        let path = window.location.pathname;
        if (path.includes('/search')) {
            let searchTerm = '';
            if (window.location.hash) {
                if (['sfx', 'soundscrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical sounds)");
                    setCategorySelected('sounds');
                } 
                else if (window.location.pathname.startsWith("/3D") || ['3d.productioncrate.com', 'rendercrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical 3d");
                    setCategorySelected('3d');
                }
                else if (['vfx', 'footagecrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical vfx");
                    setCategorySelected('vfx');
                }
                else if (['graphics', 'graphicscrate'].some(sub => window.location.hostname.includes(sub))) {
                    console.log("vertical graphics");
                    setCategorySelected('graphics');
                }
                
                searchTerm = window.location.hash.split('#!/')[1].split('&')[0];
            } else {
                const urlParams = new URLSearchParams(window.location.search);
                const mainCategory = urlParams.get('main_category');
                if (['all', 'vfx', 'sounds', '3d', 'graphics'].some(c => c===mainCategory)) {
                    setCategorySelected(mainCategory);
                }
                searchTerm = path.split('/search/')[1].split('?')[0];
            }
            searchTerm.replace('+', ' ');
            if (searchTerm.length>0){
                console.log("SEARCH TERM", searchTerm, searchTerm.replace("+", " "));
                setSearchQuery(searchTerm);
                setSearchFocused(true);
            }
        }
    }, []);

    useEffect(() => {
        //console.log("header user", user);
        if (!user) return;
        if(user.team && user.team.subscription && user.team.subscription.plan){
            let tempPlan = user.team.subscription.plan;
            tempPlan.features.forEach(feat => {
                if(feat.name === 'assets-download'){
                    if (feat.policy && feat.policy.name.toLowerCase().includes('free')) {
                        setPremiumAssetsPolicy(false);
                    } else {
                        setPremiumAssetsPolicy(true);
                    }
                }
            });
        }

        const skipUserProfile = user.settings?.skipUserProfile;
        if (skipUserProfile) {
            return;
        }
        const profession = user.settings?.profession;
        const software_preferences = user.settings?.software_preferences;
        const usage_purpose = user.settings?.usage_purpose;

        if (profession && software_preferences && usage_purpose) {
            return;
        }

        if (profession && !software_preferences && !usage_purpose) setAnswered(1);
        if (profession && software_preferences && !usage_purpose) setAnswered(2);

        const previousAnswers = [profession, software_preferences, usage_purpose];
        setSurveyData(prev => {
            let newData = {...prev};
            previousAnswers.forEach((quest,i) => {
                console.log("quest", quest);
                if (!quest) return;
                if (Array.isArray(quest)) {
                    newData.questions[i].answers.forEach(ans => {
                        ans.selected = quest.includes(ans.value);
                        if(ans.selected) console.log("ans in array", ans, quest);
                    });
                }
                if (typeof quest === 'string') {
                    newData.questions[i].answers.forEach(ans => {
                        ans.selected = ans.value === quest.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '');
                        if(ans.selected) console.log("ans as string", ans, quest);
                    });
                }
            });

            return newData;    
        });
        
        setShowUserProfileBanner(true);
        console.log("SETTINGS", user.settings);

        
    }, [user]);


    useEffect(() => {
        if (isAuthenticating) return;

        
        const runCallback = () => {
            if (window.globalSessionCallback && typeof window.globalSessionCallback === 'function') {
                window.globalSessionCallback(user, Analytics);
            } else {
                window.getGlobalSession = () => ({user, analytics: Analytics});
            }
        };
    
        if (document.readyState === "complete") {
            runCallback();
        } else {
            window.addEventListener("load", runCallback, { once: true });
        }
    }, [user, isAuthenticating]);

    useEffect(() => {
        if (answered && answered>0) {
            const data = {};
            data[surveyData.questions[answered-1].value] = surveyData.questions[answered-1].answers.filter(a => a.selected).map(a => a.value);
            meFn(data);
        }
        if (answered === 3) {
            setThankYou(surveyData.thankYou);
        }
    }, [answered]);

    const handleCloseUserProfileSurvey = () => {
        setShowUserProfileBanner(false);
        setSurveyVisible(false);
        const data = {
            skipUserProfile: answered>=3
        };
        if (usedForText) {
            data.usedForText = usedForText;
        }
        meFn(data);
    };

    const handleSearch = (event) => {
        event.preventDefault();

        searchAssets(searchQuery, categorySelected, newUrlScheme);
        //setSearchQuery('');
    };

    const handleLogout = () => {
        // If we're not on user.productioncrate.com, redirect to the logout page there
        if (window.location.hostname !== "user.productioncrate.com" && window.location.hostname !== "user-qa.productioncrate.com") {
            window.location.href = "https://user.productioncrate.com/logout";
            return;
        }
        unloadUser();
    };


    const reactivateUser = (provider, planId) => {
        setLoading(true);
        let postData = {provider, planId};
        
        subscribeUserFn(postData).then(data => {

            if(data.url){
                document.location.href = data.url;
            } else {
                console.log("Problem while subscribing user ie", data);
                document.location.href = '/register';
            }
            setLoading(false);
        })
        .catch(e => {
            console.log("Problem while subscribing user tc", e);
            setLoading(false);
            document.location.href = '/register';
        });

        

    };

    const categorySelector = useClickable(() =>{
        setSelectorFocus(true);
        setCategorySelectorExpanded(true);
    },
    () => {
        setTimeout(() => setSelectorFocus(false), 500);
        setCategorySelectorExpanded(false);
    });

    const toggleCategorySelected = (index) => {
        if (categorySelected === index) {
            setCategorySelected('all');
        } else {
            setCategorySelected(index);
        }
    };

    const selectorText = (category) => {
        let MAP_CAT = {
            'all': 'All Categories',
            'vfx': 'VFXs',
            'mograph': 'Motion Graphics',
            'sounds': 'SFXs',
            'graphics': 'Graphics',
            '3d': '3D',
            'tutorials': 'Tutorials'
        };
        return MAP_CAT[category];
    };
    return (
        <div className={`header ${focusMode?'fade-out':''} ${minimal?'minimal':''} `}>
            <Survey />
            <Banner />
            <div className={`first-row ${floatingVisible?'hidden':''}`}>
                <div className="wrapper">
                <a className="logo m-r-24 xs:m-r-0" href="https://www.productioncrate.com">
                        <img className="pic" src={`${baseHref}${Logo}`} alt="Logo" />
                        <div className="name">
                            <img src={`${baseHref}${LogoName}`} alt="logo name" />
                            {showBrandSubtitle &&
                            <div className="brand_subtitle">
                                by ProductionCrate
                            </div>
                            }
                        </div>
                    </a>
                    {!minimal && 
                    <form className={`search-bar ${searchFocused || selectorFocus?'focused':''} m-l-a`} id="js-search-form" onSubmit={handleSearch} >
                        <img className="logo" src={`${baseHref}${Logo}`} alt="Logo" />
                            <div className={`chip options-select ${categorySelectorExpanded?'expanded':''}`} ref={categorySelector} >
                                {`${selectorText(categorySelected)}`}
                                <span className="glyphicon glyphicon-down chevron-icon m-l-a"></span>
                                <div className="options">
                                    <div className={`item ${['all'].includes(categorySelected) ? 'selected' : ''}`} onClick={() => toggleCategorySelected('all')}>
                                        All <span className={`glyphicon glyphicon-${['all'].includes(categorySelected) ? 'check' : 'unchecked'}`}></span>
                                    </div>
                                    <div className={`item ${['vfx', 'all'].includes(categorySelected) ? 'selected' : ''}`} onClick={() => toggleCategorySelected('vfx')}>
                                        VFX & Motions<span className={`glyphicon glyphicon-${['vfx', 'all'].includes(categorySelected) ? 'check' : 'unchecked'}`}></span>
                                    </div>
                                    <div className={`item ${['sounds', 'all'].includes(categorySelected) ? 'selected' : ''}`} onClick={() => toggleCategorySelected('sounds')}>
                                        Sounds <span className={`glyphicon glyphicon-${['sounds', 'all'].includes(categorySelected) ? 'check' : 'unchecked'}`}></span>
                                    </div>
                                    <div className={`item ${['graphics', 'all'].includes(categorySelected) ? 'selected' : ''}`} onClick={() => toggleCategorySelected('graphics')}>
                                        Graphics  <span className={`glyphicon glyphicon-${['graphics', 'all'].includes(categorySelected) ? 'check' : 'unchecked'}`}></span>
                                    </div>
                                    <div className={`item ${['3d', 'all'].includes(categorySelected) ? 'selected' : ''}`} onClick={() => toggleCategorySelected('3d')}>
                                        3D  <span className={`glyphicon glyphicon-${['3d', 'all'].includes(categorySelected) ? 'check' : 'unchecked'}`}></span>
                                    </div>
                                </div>
                            </div>
                            <input 
                                type="text" 
                                value={searchQuery} 
                                onChange={({target}) => setSearchQuery(target.value)} 
                                onFocus={() => setSearchFocused(true)} 
                                onBlur={() => {
                                    if(!searchQuery ){ 
                                        setSearchFocused(!!searchQuery);
                                    }
                                }} 
                                name="query" 
                                id="header-search-bar-input"
                                placeholder={searchFocused || selectorFocus?`Try searching "new" to see recent uploads`:'Search'}
                                spellCheck="True" 
                                autoComplete="off" 
                                dir="auto" 
                                className={`search-bar-input`}
                            />
                            <span 
                                onFocus={() => setSearchFocused(true)} className={`close glyphicon glyphicon-cross ${searchQuery?'visible':''}`} 
                                onClick={() => {
                                    setSearchQuery('');
                                    setTimeout(() => setSearchFocused(false), 350);
                                }} 
                            />
                            <button type="submit" className="form-btn btn-search">
                                <span className="glyphicon glyphicon-search" />
                            </button>
                    </form>
                    }
                    <div className="controls m-l-a">

                        {!premiumAssetsPolicy && user &&
                        <div className="desktop_only">
                            
                            {user.team.last_paid_subscription &&
                            <div className="item show-logged-free accent" style={{cursor: 'pointer'}} onClick={() => reactivateUser(user.team.last_paid_subscription.provider, user.team.last_paid_subscription.plan_id)}>
                                Restore Subscription
                            </div>
                            }
                            {!user.team.last_paid_subscription &&
                            <>
                                {false &&
                                <a className="item show-logged-free" href={`${baseHref}/upgrade`}>
                                    <span className="glyphicon glyphicon-credit-card"></span>
                                </a>
                                }
                                {false &&
                                <a className="item show-logged-free" href={`${baseHref}/upgrade/?prov=p`}>
                                    <span className="glyphicon glyphicon-paypal"></span>
                                </a>
                                }
                                <a className="item show-logged-free accent" href={`${baseHref}/upgrade`}>
                                    Upgrade to Pro
                                </a>
                            </>
                            }
                        </div>
                        }
                        {premiumAssetsPolicy && user && getEnvironment() !== 'webapp' &&
                        <div className="desktop_only m-r-24">
                            <ParticleSystem>
                            <ButtonWithGradient />
                            </ParticleSystem>
                        </div>
                        }
                        {premiumAssetsPolicy && user && getEnvironment() === 'webapp' &&
                        <div className="desktop_only m-r-24">
                            <a className="item show-logged-free" href={`https://www.productioncrate.com/`}>
                                Switch Design Back
                            </a>
                        </div>
                        }
                        {!user && !isAuthenticating && 
                        <>
                            <a className="item show-logged-out" href={`${baseHref}/member`}>
                                Login
                            </a>
                            <a className="item show-logged-out accent" href={`${baseHref}/register`}>
                                Register
                            </a>
                        </>
                        }
                        
                    </div>
                    {user && !isAuthenticating &&
                    <div className="account-menu m-l-12">
                        <span className="glyphicon glyphicon-down"></span>
                        {user && 
                            <div className="username" >
                                {user.username?user.username:user.email}
                            </div>
                        }
                        {!user && 
                            <div className="username" >
                                Account
                            </div>
                        }
                        {false &&
                        <div className="profile-pic" >
                            <img src={`https://www.gravatar.com/avatar/${md5((user?user.email:'').trim().toLowerCase())}?s=200&d=${encodeURIComponent('https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg')}`} />
                        </div>
                        }
                        <ProfilePicture user={user} progress={100*(answered + (surveyData.questions[answered].answers.find(a => a.selected)?1:0) )/surveyData.questions.length} />
                        <div className="submenu" id="js-account-menu">
                            {assetsAvailable !== null && user &&
                            <div className="item head">
                                {assetsAvailable} downloads remaining today.
                            </div>
                            }
                            {user && 
                            <a className="item show-logged-free show-logged-pro" href={`${baseHref}/member`}>
                                <i className="glyphicon glyphicon-user"></i>
                                Member Page
                            </a>
                            }
                            {!user && 
                            <>
                                <a className="item show-logged-out" href={`${baseHref}/login`}>
                                    <i className="glyphicon glyphicon-user"></i>
                                    Login
                                </a>
                                <a className="item show-logged-out" href={`${baseHref}/register`}>
                                    <i className="glyphicon glyphicon-lock"></i>
                                    Register
                                </a>
                                {false &&
                                <a className="item show-logged-out" href={`${baseHref}/register`}>
                                    <i className="glyphicon glyphicon-facebook-square"></i>
                                    Facebook Connect
                                </a>
                                }
                                <a className="item show-logged-out" href={`${baseHref}/register`}>
                                    <i className="glyphicon glyphicon-google-plus-square"></i>
                                    Google Connect
                                </a>
                            </>
                            }
                            {!premiumAssetsPolicy &&
                            <>
                                <a className="item show-logged-free" href={`${baseHref}/upgrade/?prov=p`}>
                                    <i className="glyphicon glyphicon-paypal"></i>
                                    PRO Upgrade (PayPal)
                                </a>
                                <a className="item show-logged-free" href={`${baseHref}/upgrade`}>
                                    <i className="glyphicon glyphicon-credit-card"></i>
                                    PRO Upgrade (credit/Debit)
                                </a>
                            </>
                            }
                            {user &&   
                                <div className="item show-logged-free show-logged-pro" onClick={handleLogout}>
                                    <i className="glyphicon glyphicon-lock"></i>
                                    Logout
                                </div>
                            }
                        </div>
                    </div>
                    }
                    {!user && isAuthenticating &&
                    <div className="account-menu m-l-12">
                        Loading user...
                    </div>
                    }
                    {!premiumAssetsPolicy && user &&
                    <div className="controls mobile_only m-l-16 m-r-12" style={{justifyContent: 'center'}}>
                        {user.team.last_paid_subscription &&
                        <div className="item show-logged-free accent" style={{cursor: 'pointer', minWidth: '240px'}} onClick={() => reactivateUser(user.team.last_paid_subscription.provider, user.team.last_paid_subscription.plan_id)}>
                            Restore Subscription
                        </div>
                        }
                        {!user.team.last_paid_subscription &&
                        <>
                            {false &&
                            <a className="item show-logged-free" style={{cursor: 'pointer', minWidth: '240px'}} href={`${baseHref}/upgrade`}>
                                <span className="glyphicon glyphicon-credit-card"></span>
                            </a>
                            }
                            {false &&
                            <a className="item show-logged-free" style={{cursor: 'pointer', minWidth: '240px'}} href={`${baseHref}/upgrade/?prov=p`}>
                                <span className="glyphicon glyphicon-paypal"></span>
                            </a>
                            }
                            <a className="item show-logged-free accent" style={{cursor: 'pointer'}} href={`${baseHref}/upgrade`}>
                                Upgrade to Pro
                            </a>
                        </>
                        }
                    </div>
                    }
                    {!minimal &&
                        <img alt="menun icon" src={`${baseHref}/images/icon.burger.svg`} className="menu-bar" onClick={() => setFloatingVisible(true)} />
                    }
                </div>
            </div>
            {!minimal && 
            <div className="second-row">
                <div className="wrapper" style={{position: 'relative'}}>
                    <Menu visible={floatingVisible} closeCallback={() => setFloatingVisible(false)} external={external} newUrlScheme={newUrlScheme} />                    
                </div>
            </div>
            }
            {user && user.email_verified === false && 
            <div className="banner">
                <div className='content' onClick={() => document.location.href='https://user.productioncrate.com/member/email-verify'}>
                Action Required: Your email address has not been verified yet. Click <a href='/member/email-verify'>here</a> to resend the verification email
                </div>
            </div>
            }
            {user && user.email_verified && showUserProfileBanner &&
            <>
                <div className="banner" style={{backgroundColor: '#ea5317'}}>
                    <div className='content' style={{width: 'max-content'}} onClick={() => setSurveyVisible(true)}>
                    Your profile is almost complete - Click here to finish!
                    </div>
                </div>
                <div className={`${s.survey} ${surveyVisible?s.visible:''}`}>
                    <div className={`${s.shadow}`} onClick={handleCloseUserProfileSurvey}/>
                    <div className={`${s.modal}`}>
                        <span className={`${s.close} `} onClick={handleCloseUserProfileSurvey}>X</span>
                        <div className={`${s.progress_bar}`}>
                            {answered<3 && 
                            <div className={`${s.progress}`} style={{width: `${100*(answered + (surveyData.questions[answered].answers.find(a => a.selected)?1:0) )/surveyData.questions.length}%`}}/>
                            }
                            {answered<=3 && 
                            <div className={`${s.progress}`} style={{width: `100%`}}/>
                            }
                        </div>
                        {!thankYou && answered<3 && surveyData.questions.filter((q, qIndex) => qIndex === answered ).map((question) =>
                        <React.Fragment key={`question-index-${answered}`}>
                            <div className={`${s.question} ${s.accent} m-b-16`}>{question.labelAccent}</div>
                            <div className={`${s.question} m-b-16`}>{question.label}</div>
                            <div className={`${s.column_wrapper}`}>
                            {question.answers.map((answer, aIndex) => 
                                <div key={`question-${answered}-answer-${aIndex}`} id={`question-1-answer-${aIndex}`} style={{display: 'flex', alignItems: 'center', gap: '8px', minWidth: '240px'}} className={`${s.answer} ${answer.selected?s.selected:''} m-b-8`} onClick={() => submitAnswer(answered, aIndex)}>
                                    {answer.icon &&
                                    <img style={{maxWidth: '25px', height: 'auto'}} src={answer.icon} alt="software logo" /> 
                                    }
                                    {answer.label}
                                </div>
                            )}
                            </div>
                            <div className={`${s.cta} ${question.answers.find(a => a.selected)?'':s.disabled}`} onClick={() => setAnswered(prev => prev+1)}>{question.cta}</div>
                            {answered>0 && 
                            <div className={`${s.back} m-t-12 m-h-a`} onClick={() => setAnswered(prev => prev-1)}>Back</div>
                            }
                            {answered===0 && 
                            <div className={`${s.back} m-t-12 m-h-a`} ></div>
                            }
                        </React.Fragment>
                        )}
                        {thankYou && 
                        <>
                            <div className={`${s.thankYou} ${s.accent} m-b-16`}>{thankYou}</div>
                            <div style={{ margin: '24px 0 12px 0', textAlign: 'center'}}>We would love to see what you have built with our assets:</div>
                            <textarea value={usedForText} onChange={(e) => setUsedForText(e.target.value)} placeholder="URL or description" style={{ height: "90px", width: "435px", padding: "8px", color: "white", background: "#1e1d1d" }} />
                            <div className={`${s.cta}`} onClick={handleCloseUserProfileSurvey}>Save & Close</div>
                        </>
                        }
                    </div>
                </div>
            </>
            }
            {loading &&
            <div style={{background: 'rgba(0,0,0,0.9)', width: '100vw', height: '100vh', top: '0', left: '0', position: 'fixed', zIndex: '9', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Spinner label="Reactivating subscription..." />
            </div>
            }
        </div>
    );
};

export default forwardRef(Header);

