import React from 'react';
import ReactDOM from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './styles/index.scss';
import { getEnvironment } from './lib/utils';
import Providers from './providers'
import { Plans, PlansV2 } from './components/Plans';
import { Enterprise } from './components/Enterprise';
import { OnlySignup } from './components/Signup';
import { Member } from './components/Member';
import { Login, Logout } from './components/Login';
import { EmailVerify } from './components/EmailVerify';
import { InviteAccept } from './components/InviteAccept';
import { PasswordRecovery } from './components/PasswordRecovery';
import reportWebVitals from './reportWebVitals';
import { Header } from './components/Header';
import { Footer } from './components/Footer';


window.crateVersion = '1.16.17';
window.bugsnagKey = process.env.REACT_APP_BUGSNAG_KEY || '748fc8c8b0858554332f17e8bc3b27ba';



const rootElement = document.getElementById("root");

if(!rootElement) {
  

  window.renderReactHeader = (domTarget) => {
    ReactDOM.createRoot(domTarget).render(
        <Providers>
          <Header external={true} />
        </Providers>
    );
  };

  window.renderReactFooter = (domTarget) => {
    ReactDOM.createRoot(domTarget).render(<Footer/>);
  };
  
} else {
  
  const router = createBrowserRouter([
    {
      path: "/",
      element: <PlansV2 />,
    },
    {
      path: "/register",
      element: <PlansV2 />,
    },
    {
      path: "/upgrade",
      element: <PlansV2 />,
    },
    {
      path: "/upgrade/:msg",
      element: <PlansV2 />,
    },
    {
      path: "/register/free",
      element: <PlansV2 donator={false} />,
    },
    {
      path: "/register/:campaign",
      element: <PlansV2 />,
    },
    {
      path: "/bregister",
      element: <PlansV2 />,
    },
    {
      path: "/bregister/free",
      element: <PlansV2 donator={false} />,
    },
    {
      path: "/bregister/:campaign?",
      element: <PlansV2 />,
    },
    {
      path: "/signup/:planId/:provider",
      element: <OnlySignup />,
    },
    {
      path: "/email-verified",
      element: <EmailVerify verify={true} />,
    },
    {
      path: "/teams/member/invite/:code",
      element: <InviteAccept />,
    },
    {
      path: "/member/email-verify",
      element: <EmailVerify />,
    },
    {
      path: "/member/success",
      element: <Member subscriptionSuccess={true} />,
    },
    {
      path: "/member",
      element: <Member />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/login/google/callback",
      element: <Login remoteCallback="google" />,
    },
    {
      path: "/login/facebook/callback",
      element: <Login remoteCallback="facebook" />,
    },
    {
      path: "/login/portal/callback",
      element: <Login remoteCallback="portal" />,
    },
    {
      path: "/login/email/callback",
      element: <Login remoteCallback="email" />,
    },
    {
      path: "/password-recovery",
      element: <PasswordRecovery />,
    },
    {
      path: "/reset-password",
      element: <PasswordRecovery />,
    },
    {
      path: "/enterprise",
      element: <Enterprise />,
    }
  ]);
  

  let WrappedComponent = (
    //<React.StrictMode>
      <Providers router={router} />
    //</React.StrictMode>
  );

  const BUGSNAG_KEY = window.bugsnagKey;

  if (BUGSNAG_KEY) {
    Bugsnag.start({
      apiKey:  BUGSNAG_KEY,
      plugins: [new BugsnagPluginReact()],
      releaseStage: getEnvironment(),
      appVersion: window.crateVersion,
    });

    const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
    WrappedComponent = (
      <ErrorBoundary>
        {WrappedComponent}
      </ErrorBoundary>
    );
  }

  ReactDOM.createRoot(rootElement).render(WrappedComponent);



  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

}