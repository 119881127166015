import { useEffect, useState } from "react";
import md5 from 'js-md5';
import './ProfilePicture.scss';

const ProfilePicture = ({ user, progress = false }) => {
    const emailHash = md5((user?.email || "").trim().toLowerCase());
    const imageUrl = `https://www.gravatar.com/avatar/${emailHash}?s=200&d=${encodeURIComponent(
      "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg"
    )}`;
  
    const radius = 90; // Ajustado para que no se corte
    const strokeWidth = 16;
    const adjustedRadius = radius - strokeWidth / 2;
    const circumference = 2 * Math.PI * adjustedRadius;
  
    const strokeDasharray = circumference;
    const strokeDashoffset = circumference * ((100 - (progress===0?15:progress)) / 100);
  
    const [progressValue, setProgressValue] = useState(circumference);
  
    useEffect(() => {
      setTimeout(() => setProgressValue(strokeDashoffset), 100);
    }, [progress]);
  
    return (
      <div className="profile-container">
        <img src={imageUrl} alt="Profile" className="profile-pic" />
        {progress !== false && (
          <svg className="progress-bar" viewBox="0 0 200 200" style={{ transform: "rotate(-90deg)" }}>
            <circle
              r={adjustedRadius}
              cx="100"
              cy="100"
              fill="transparent"
              stroke="#e0e0e0"
              strokeWidth={strokeWidth}
            ></circle>
            <circle
              r={adjustedRadius}
              cx="100"
              cy="100"
              stroke="#f36c17"
              strokeWidth={strokeWidth}
              strokeLinecap="round"
              fill="transparent"
              className="progress-ring"
              style={{ strokeDasharray, strokeDashoffset: progressValue }}
            ></circle>
          </svg>
        )}
      </div>
    );
};

export default ProfilePicture;
